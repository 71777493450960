<template>
  <div>
    <div style="padding: 20px;">
      <logoComponent />
    </div>
    <div class="d-flex align-items-center" style="margin-top: 200px;">
      <CContainer fluid>
        <CModal
          :show.sync="passwordReset"
          :no-close-on-backdrop="true"
          title="Mot de passe réinitialisé"
          size="lg"
          color="dark"
        >
          <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
            Votre mot de passe a bien été modifié 👍
          </p>
          <p>
             Vous pouvez vous connecter directement sur <router-link to="/pages/login">la page de connexion</router-link>
          </p>
          <template #footer>
            <CButton @click="passwordReset = false" color="dark">Fermer</CButton>
          </template>
        </CModal>

        <CRow class="justify-content-center">
          <CCol md="8">
            <CForm v-on:submit.prevent="confirmResetPassword()">
              <h1 class="text-center">Réinitialiser votre mot de passe</h1>

              <CInput
                label="Votre nouveau mot de passe"
                type="password"
                maxlength="249"
                v-model="password"
                @input="$v.password.$touch()"
                :isValid="$v.password.$dirty ? !$v.password.$error : null"
                invalid-feedback="Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial."
              >
              </CInput>

              <CInput
                label="Confirmation de votre nouveau mot de passe"
                type="password"
                maxlength="249"
                v-model="passwordConfirmation"
                @input="$v.passwordConfirmation.$touch()"
                :isValid="$v.passwordConfirmation.$dirty ? !$v.passwordConfirmation.$error : null"
                invalid-feedback="Veuillez rentrer deux mots de passe identiques"
              >
              </CInput>

              <CRow class="mt-4">
                <CCol class="text-center">
                  <CButton
                    type="submit" :color="confirmResetPasswordButtonStyle"
                    block class="px-4"
                    :disabled="$v.password.$invalid || $v.passwordConfirmation.$invalid || confirmPasswordResetingInProcess">
                    {{ confirmResetPasswordButtonText }}</CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'

import { password_validation } from '@/validators/validators'

import { AuthAPIService } from '@/api/APIAuth'
import logoComponent from '@/components/logoComponent'

const apiAuth = new AuthAPIService()


export default {
  name: 'PasswordResetConfirm',
  components: {
    logoComponent
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      password: '',
      passwordConfirmation: '',
      confirmResetPasswordToken: '',

      confirmResetPasswordButtonText: 'Réinitialiser votre mot de passe',
      confirmResetPasswordButtonStyle: 'outline-primary',
      confirmPasswordResetingInProcess: false,

      errorPasswordMessage: '',
      passwordReset: false
    }
  },
  mounted: function() {
    this.confirmResetPasswordToken = this.$route.query.token
  },
  validations: {
    password: {
      required,
      maxLength: maxLength(249),
      minLength: minLength(8),
      password_validation
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs('password'),
      maxLength: maxLength(249),
      minLength: minLength(2)
    }
  },

  methods: {
    confirmResetPassword () {
      this.confirmResetPasswordButtonText = 'Réinitialisation en cours'
      this.confirmResetPasswordButtonStyle = 'secondary'
      this.confirmPasswordResetingInProcess = true
      apiAuth.confirmResetPassword(this.confirmResetPasswordToken, this.password )
      .then(() => {
          this.passwordReset = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.confirmResetPasswordButtonText = 'Réinitialiser votre mot de passe'
        this.confirmResetPasswordButtonStyle = 'outline-primary'
        this.confirmPasswordResetingInProcess = false
      })
    },
  }
}
</script>
